<template>
  <el-card class="box-card">
    <div class="box-flex">
      <el-page-header
        class="product-header"
        @back="goBack"
        content="修改会员信息"
      ></el-page-header>
      <div class="select-container">
        <el-form
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="left"
          :rules="rules"
          v-loading.fullscreen.lock="fullscreenLoading"
        >
          <el-form-item label="会员编号" prop="cardNo">
            <el-input
              style="width: 30%"
              v-model="info.cardNo"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <div class="NoStyleSucces" v-show="succesShow">
            <i class="el-icon-check iconStyle"
              >&nbsp;&nbsp;<span style="color: #999">该编号可用</span></i
            >
          </div>
          <div class="NoStyleerror" v-show="errorShow">
            <i class="el-icon-close iconStyle"
              >&nbsp;&nbsp;<span style="color: #999">该编号已被占用</span></i
            >
          </div>
          <el-form-item style="width: 40%" label="会员名" required>
            <el-input v-model="info.name"></el-input>
          </el-form-item>
          <el-form-item style="width: 40%" label="昵称">
            <el-input v-model="info.Nname"></el-input>
          </el-form-item>
          <el-form-item label="合同编号" required>
            <el-input
              style="width: 30%"
              v-model="info.contractNo"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="合同金额" required>
            <el-input-number
              v-model="info.contractPrice"
              :precision="2"
              :step="1"
              :min="0.1"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="余额" required>
            <el-input-number
              v-model="info.vipLimit"
              :precision="2"
              :step="1"
              :min="0.1"
            ></el-input-number>
          </el-form-item>
          <el-form-item style="width: 40%" label="联系电话">
            <el-input
              v-model="info.phonenum"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item style="width: 40%" label="店铺">
            <el-input
              v-model="info.vipStore"
              placeholder="请输入店铺名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="登陆密码">
            <el-input
              v-model="info.password"
              style="width: 30%"
              placeholder="密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="会员状态">
            <el-radio v-model="info.state" label="1">正常</el-radio>
            <el-radio v-model="info.state" label="2">冻结</el-radio>
          </el-form-item>
          <el-form-item style="width: 30%" label="创建人">
            <el-input v-model="info.creator" :disabled="true"> </el-input>
          </el-form-item>
          <el-form-item style="width: 40%" label="创建时间">
            <el-input v-model="info.createTime" :disabled="true"> </el-input>
          </el-form-item>
          <el-form-item style="width: 60%" label="备注">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="info.textarea"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存修改</el-button>
            <el-button @click="onCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="vip-list">
      <h3>
        会员余额变动表<span
          ><span
            style="backgroundcolor: oldlace; fontweight: 500; fontsize: 12px"
            >(青绿色为新增;</span
          ><span
            style="backgroundcolor: #f0f9eb; fontweight: 500; fontsize: 12px"
            >浅黄色为消费扣除)</span
          ></span
        >
      </h3>
      <el-table
        :data="moneyList"
        style="width: 100%"
        height="250"
        :cell-style="csllStyle"
        :row-class-name="tableRowClassName"
      >
        <el-table-column fixed prop="logsTime" label="日期" width="180">
        </el-table-column>
        <el-table-column prop="money" label="费用" width="180">
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import Big from "big.js";
import moment from "moment";
import secret from "@/utils/secret.js";
export default {
  data() {
    let self = this;
    let NoEvent = function () {
      self.verify();
    };
    return {
      info: "",
      fullscreenLoading: true,
      succesShow: false,
      errorShow: false,
      oldMoney: "",
      moneyList: "",
      rules: {
        cardNo: [
          {
            required: true,
            message: "请输入合同编号",
            trigger: "blur",
            validator: NoEvent,
          },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    const cardNo = this.$route.params.id;
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    const single = true;
    let userKey = this.userKey;
    axios
      .post(this.GLOBAL.api + "/creat/vip", { single, cardNo, userKey })
      .then((res) => {
        this.info = res.data[0];
        this.oldMoney = this.info.vipLimit;
        this.moneyList = JSON.parse(this.info.moneyLogs);
        // console.log("this.moneyList:", this.moneyList);
        this.fullscreenLoading = false;
      });
  },
  methods: {
    verify() {
      let cardNo = this.info.cardNo;
      const check = true;
      let userKey = this.userKey;
      axios
        .post(this.GLOBAL.api + "/creat/vip", { check, cardNo, userKey })
        .then((res) => {
          if (res.data.statusCode === 200 && this.info.cardNo !== "") {
            this.succesShow = true;
            this.errorShow = false;
          } else if (res.data.statusCode === 201) {
            this.errorShow = true;
            this.succesShow = false;
          } else {
            this.$message({
              message: "请检查合同信息",
              type: "warning",
            });
          }
        });
    },
    goBack() {
      this.$router.push("/member/list");
    },
    onSubmit() {
      // console.log("======", JSON.parse(this.info.moneyLogs));
      let doMoney = "";
      if (+this.oldMoney !== this.info.vipLimit) {
        const nowMoney = {
          money: +Big(this.info.vipLimit).minus(+this.oldMoney), // 余额变动差额
          logsTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        };
        if (nowMoney !== null && nowMoney !== undefined && nowMoney) {
          var oldArr = JSON.parse(this.info.moneyLogs);
          oldArr.push(nowMoney);
          doMoney = JSON.stringify(oldArr);
        } else {
          doMoney = this.info.moneyLogs;
          this.$message({
            type: 'error',
            message: '本次积分/余额变动未记录成功！'
          })
        }
      } else {
        doMoney = this.info.moneyLogs;
      }
      this.info.updateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      const ruleFormData = this.info;
      const fix = true;
      let userKey = this.userKey;
      axios
        .post(this.GLOBAL.api + "/creat/vip", {
          fix,
          ruleFormData,
          userKey,
          doMoney,
        })
        .then((data) => {
          if (data.data.statusCode == 400) {
            this.$message({
              message: "修改失败，请联系管理员",
              type: "warning",
            });
          } else {
            this.$message({
              message: "会员信息修改成功",
              type: "success",
            });
            this.$router.push("/member/list");
          }
        });
    },
    onCancel() {
      this.$confirm("您还未保存修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/member/list");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    csllStyle(row) {
      // console.log(row.row.money);
      // 监听tabel数据进行操作
      if (+row.row.money < 0) {
        return "background: oldlace";
      } else {
        return "background: #f0f9eb";
      }
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 1% 3%;
  position: relative;
}
.box-card {
  width: 100%;
  height: 100%;
  position: relative;
}
.iconStyle {
  color: green;
  font-weight: bold;
  position: absolute;
  top: 2%;
  left: 40%;
}
.vip-list {
  position: absolute;
  right: 5%;
  top: 20%;
}
</style>